.Programs{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;

}
.program-header{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-style: italic;
}
.program-categories{
    display: flex;
    gap: 1rem;
}
.categories{
    display: flex;
    flex-direction: column;
    background-color: gray;
    padding: 2rem;
    gap: 1rem;
    color: white;
    justify-content: space-between;
}

.categories>:nth-child(1){
    width: 2rem;
    height: 2rem;fill: white;
}

.categories>:nth-child(2){
   font-size: 1rem;
   font-weight: bold;
}

.categories>:nth-child(3){
    font-size: 0.9rem;
    line-height: 25px;
 }

 .join-now{
    display: flex;
    align-items: center;
    gap: 2rem;
 }

 .categories:hover{
    background-color: var(--orange);
    cursor: pointer;
 }
 @media  screen and (max-width: 768px) 
 {
    .program-header{
        flex-direction: column !important;
        gap: 1rem !important;
        font-size: x-large !important;
        align-items: center !important;
        justify-content: center !important;
        margin-top: 2rem !important;
    }
    .program-categories{
        flex-direction: column;
    }
}