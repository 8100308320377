.Footer-Container{
    position: relative;
}
/* .Footer-Container>hr{
    border: 1px solid var(--lightGrey);
} */
.footer{
    display: flex;
    padding: 1rem 2rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 20rem;
    gap: 4rem;
}
.social-links{
    display: flex;
    gap: 4rem;
}
.social-links>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}
.logo-f{
    width: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media  screen and (max-width: 768px){
    .logo-f{
        width: 6rem !important;
        height: 6rem !important;
        
    }
}